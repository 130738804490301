body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

::-ms-clear {
  display: none;
}

.form-control-clear {
  z-index: 10;
  pointer-events: auto;
  cursor: pointer;
}

/* Always set the map height explicitly to define the size of the div
 * element that contains the map. */
 #map {
  height: 400px;
  width: 100%;
}
/* Optional: Makes the sample page fill the window. */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}
table {
  font-size: 12px;
}
#listing {
  position: absolute;
  width: 200px;
  height: 470px;
  overflow: auto;
  left: 442px;
  top: 0px;
  cursor: pointer;
  overflow-x: hidden;
}
#findhotels {
  position: absolute;
  text-align: right;
  width: 100px;
  font-size: 14px;
  padding: 4px;
  z-index: 5;
  background-color: #fff;
}
#locationField {
  position: absolute;
  width: 190px;
  height: 25px;
  left: 108px;
  top: 0px;
  z-index: 5;
  background-color: #fff;
}
#controls {
  position: absolute;
  left: 300px;
  width: 140px;
  top: 0px;
  z-index: 5;
  background-color: #fff;
}
#autocomplete {
  width: 100%;
}
#country {
  width: 100%;
}
.placeIcon {
  width: 20px;
  height: 34px;
  margin: 4px;
}
.hotelIcon {
  width: 24px;
  height: 24px;
}
#resultsTable {
  border-collapse: collapse;
  width: 240px;
}
#rating {
  font-size: 13px;
  font-family: Arial Unicode MS;
}
.iw_table_row {
  height: 18px;
}
.iw_attribute_name {
  font-weight: bold;
  text-align: right;
}
.iw_table_icon {
  text-align: right;
}
