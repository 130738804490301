/* Always set the map height explicitly to define the size of the div
 * element that contains the map. */
 #map {
  height: 400px;
  width: 100%;
}
/* Optional: Makes the sample page fill the window. */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}
table {
  font-size: 12px;
}
#listing {
  position: absolute;
  width: 200px;
  height: 470px;
  overflow: auto;
  left: 442px;
  top: 0px;
  cursor: pointer;
  overflow-x: hidden;
}
#findhotels {
  position: absolute;
  text-align: right;
  width: 100px;
  font-size: 14px;
  padding: 4px;
  z-index: 5;
  background-color: #fff;
}
#locationField {
  position: absolute;
  width: 190px;
  height: 25px;
  left: 108px;
  top: 0px;
  z-index: 5;
  background-color: #fff;
}
#controls {
  position: absolute;
  left: 300px;
  width: 140px;
  top: 0px;
  z-index: 5;
  background-color: #fff;
}
#autocomplete {
  width: 100%;
}
#country {
  width: 100%;
}
.placeIcon {
  width: 20px;
  height: 34px;
  margin: 4px;
}
.hotelIcon {
  width: 24px;
  height: 24px;
}
#resultsTable {
  border-collapse: collapse;
  width: 240px;
}
#rating {
  font-size: 13px;
  font-family: Arial Unicode MS;
}
.iw_table_row {
  height: 18px;
}
.iw_attribute_name {
  font-weight: bold;
  text-align: right;
}
.iw_table_icon {
  text-align: right;
}